(function ($) {

    "use strict";

    $(window).on('load', function () {
        $('[data-loader="circle-side"]').fadeOut(); // will first fade out the loading animation
        $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
        $('body').delay(350);
        $('#hero_in h1,#hero_in form').addClass('animated');
        $('.hero_single, #hero_in').addClass('start_bg_zoom');
        $(window).scroll();
    });

    // Sticky nav
    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 1) {
            $('.header').addClass("sticky");
        } else {
            $('.header').removeClass("sticky");
        }
    });

    // Sticky sidebar
    $('#sidebar').theiaStickySidebar({
        additionalMarginTop: 150
    });

    // Mobile Mmenu
    var $menu = $("nav#menu").mmenu({
            "extensions": ["pagedim-black"],
            counters: false,
            keyboardNavigation: {
                enable: true,
                enhance: true
            },
            navbar: {
                title: 'MENU'
            }
        },
        {
            // configuration
            clone: true,
            classNames: {
                fixedElements: {
                    fixed: "menu_2",
                    sticky: "sticky"
                }
            }
        });
    var $icon = $("#hamburger");
    var API = $menu.data("mmenu");
    $icon.on("click", function () {
        API.open();
    });
    API.bind("open:finish", function () {
        setTimeout(function () {
            $icon.addClass("is-active");
        }, 100);
    });
    API.bind("close:finish", function () {
        setTimeout(function () {
            $icon.removeClass("is-active");
        }, 100);
    });

    // Header button explore
    $('a[href^="#"].btn_explore').on('click', function (e) {
        e.preventDefault();
        var target = this.hash;
        var $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 800, 'swing', function () {
            window.location.hash = target;
        });
    });

    // WoW - animation on scroll
    var wow = new WOW(
        {
            boxClass: 'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset: 0,          // distance to the element when triggering the animation (default is 0)
            mobile: true,       // trigger animations on mobile devices (default is true)
            live: true,       // act on asynchronously loaded content (default is true)
            callback: function (box) {
                // the callback is fired every time an animation is started
                // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
        }
    );
    wow.init();

    // tooltips
    $('[data-toggle="tooltip"]').tooltip();

    // Selectbox
    $(".selectbox").selectbox();

    // Check and radio input styles
    $('input.icheck').iCheck({
        checkboxClass: 'icheckbox_square-grey',
        radioClass: 'iradio_square-grey'
    });

    // Secondary nav scroll
    var $sticky_nav = $('.secondary_nav');
    $sticky_nav.find('a').on('click', function (e) {
        e.preventDefault();
        var target = this.hash;
        var $target = $(target);
        $('html, body').animate({
            'scrollTop': $target.offset().top - 150
        }, 800, 'swing');
    });
    $sticky_nav.find('ul li a').on('click', function () {
        $sticky_nav.find('ul li a.active').removeClass('active');
        $(this).addClass('active');
    });

    $('ul#cat_nav li a').on('click', function () {
        $('ul#cat_nav li a.active').removeClass('active');
        $(this).addClass('active');
    });

})(window.jQuery); 